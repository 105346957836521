<template>
    <div>
        <vs-button type="border" class="mr-2 px-3 rounded-lg" @click="goToRanking" >
            <div class="flex flex-nowrap">
                <feather-icon icon="AwardIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
            </div>
        </vs-button>
    </div>
</template>
<script>

export default {
    name:'challenge-ranking-button',
    props: {
        properties: {type: Object, required: true}
    },
    methods:{
        goToRanking(){
            this.$router.push({name: 'admin-challenge-ranking', params: {"uuid": this.properties.uuid}});
        }
    }
}
</script>

<style scoped>

.action-button button {
    @apply h-12 !important;
}
</style>
